// This is a catch-all route.
// It is the entry point for handling entity routes from Drupal.
import { GetStaticPathsResult, GetStaticPropsResult } from 'next';
import {DrupalNode, DrupalTaxonomyTerm} from 'next-drupal';
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';

import { getMenus } from '../lib/get-menus';
import { LayoutProps } from '../components/layout';
import { drupal } from '../lib/drupal';
import { getPrioritizedStaticPathsFromContext } from '../lib/get-prioritized-static-paths';
import { GetStaticPathsContext } from 'next/types';
import {
  BlurMap,
  DrupalMainMenuLinkContent,
  DrupalProductNode, DrupalVideoMedia, DrupalWebform,
  IndustrySegmentsTaxonomyTerm, ProductClassTaxonomyTerm,
  ProductTypeTaxonomyTerm, TranslatedUrls
} from "../types/hygiena-types";
import {
  getFullPageNodeProductIncludes,
} from "../lib/get-includes";
import {getBlurMap} from "../lib/get-blur-map";
import {testApiCompatibility} from "../lib/test-api-compatibility";
import {useEffect} from "react";
import {useRouter} from "next/router";

// Entity Processing
import {TermProductClassSlug} from "../lib/slug/term--product-class-slug";
import {NodeArticleSlug} from "../lib/slug/node--article-slug";
import {NodeEventSlug} from "../lib/slug/node--event-slug";
import {NodeProductSlug} from "../lib/slug/node--product-slug";
import {NodeLandingPageSlug} from "../lib/slug/node--landing-page-slug";
import {TermIndustrySegmentsSlug} from "../lib/slug/term--industry-segments-slug";
import {TermProductTypeSlug} from "../lib/slug/term--product-type-slug";

// Page Types
import dynamic from 'next/dynamic';
import {purgeSlug} from "../lib/purge-slug";
import {getAlertBanner} from "../lib/get-alert-banner";
import {getDictionary} from "../lib/dictionaries";
import {MediaVideoSlug} from "../lib/slug/media--video-slug";
import striptags from "striptags";
const NodeArticle = dynamic(() => import('../components/templates/nodes/node--article').then(mod => mod.NodeArticle));
const NodeBasicPage = dynamic(() => import('../components/templates/nodes/node--page').then(mod => mod.NodeBasicPage));
const NodeEvent = dynamic(() => import('../components/templates/nodes/node--event').then(mod => mod.NodeEvent));
const NodeLandingPage = dynamic(() => import('../components/templates/nodes/node--landing_page').then(mod => mod.NodeLandingPage));
const NodeProduct = dynamic(() => import('../components/templates/nodes/node--product').then(mod => mod.NodeProduct));
const TaxonomyIndustrySegments = dynamic(() => import("../components/templates/taxonomy/taxonomy--industry_segments").then(mod => mod.TaxonomyIndustrySegments));
const TaxonomyProductClass = dynamic(() => import('../components/templates/taxonomy/taxonomy--product_class').then(mod => mod.TaxonomyProductClass));
const TaxonomyProductType = dynamic(() => import('../components/templates/taxonomy/taxonomy--product_type').then(mod => mod.TaxonomyProductType));
const MediaVideo = dynamic(() => import("../components/templates/media/media--video").then(mod => mod.MediaVideo));



// If there is no revalidate ttl stored in config, default to 15 minutes.
export const REVALIDATE_TTL = process?.env?.REVALIDATE_TTL ?? "900";

// List of all the entity types handled by this route.
export const ENTITY_TYPES = [
  'node--page',
  'node--article',
  'node--event',
  'node--press_release',
  'node--product',
  'node--landing_page',
  'taxonomy_term--product_type',
  'taxonomy_term--product_class',
  'taxonomy_term--industry_segments',
  'media--video',
];

interface EntityPageProps extends LayoutProps {
  entity: DrupalNode | DrupalTaxonomyTerm | DrupalVideoMedia;
  additionalContent?: {
    paragraphs?: {}
  };
  entityInfo?: {
    id?: string,
    type?: string,
  },
  blurMap?: BlurMap,
  metatag?: any,
  newsletterPad?: boolean,
  customMenu?: DrupalMainMenuLinkContent[],
  hideUtilityMenu,
  hideFooterMenu,
  logoScrollToTop,
}

export default function EntityPage({
  entity,
  additionalContent,
  redirectTo,
}: EntityPageProps) {
  const key = `page--${entity?.id}`;
  const router = useRouter();

  useEffect(() => {
    if (redirectTo) router.push(redirectTo);
  });

  return (
    <>
      {entity?.type === 'media--video' && (
        // @ts-ignore
        <MediaVideo video={entity as DrupalVideoMedia} additionalContent={additionalContent}/>
      )}
      {entity?.type === 'node--page' && (
        <NodeBasicPage key={key} node={entity as DrupalNode} />
      )}
      {entity?.type === 'node--landing_page' && (
        <NodeLandingPage key={key} node={entity as DrupalNode} />
      )}
      {entity?.type === 'node--article' && (
        <NodeArticle
          key={key}
          node={entity as DrupalNode}
          imageField={entity?.field_article_image}
          categoryTerm={entity?.field_article_type}
          date={entity.created}
          additionalContent={additionalContent}
        />
      )}
      {entity?.type === 'node--press_release' && (
        <NodeArticle
          key={key}
          node={entity as DrupalNode}
          imageField={entity?.field_pr_image}
          date={entity.created}
          additionalContent={additionalContent}
        />
      )}
      {entity?.type === 'node--event' && (
        <NodeEvent
          key={key}
          node={entity as DrupalNode}
          date={entity?.field_event_start_date}
          categoryTerm={entity?.field_event_type}
          additionalContent={additionalContent}
          layout={entity?.field_event_layout}
        />
      )}
      {entity?.type === 'node--product' && (
        // eslint-disable-next-line react/jsx-no-undef
        <NodeProduct
          key={key}
          node={entity as DrupalProductNode}
          additionalContent={additionalContent as {relatedNews: DrupalNode[], extraProducts: DrupalProductNode[]}}
        />
      )}
      {entity?.type === 'taxonomy_term--product_type' && (
        <>
          {(Array.isArray(additionalContent?.['products']) && additionalContent?.['products'].length === 1) ? (
            // @todo let's handle this better for when we're rendering 1 product in a Product Type/Name.
            <NodeProduct
              key={key}
              node={additionalContent['products'][0] as DrupalProductNode}
              additionalContent={{
                relatedNews: additionalContent['relatedNews'],
                compatibleProducts: additionalContent?.['compatibleProducts'] ?? [],
                compatibleMeta: additionalContent?.['compatibleMeta'],
              }}/>
          ): (
            <TaxonomyProductType
              key={key}
              additionalContent={additionalContent as {
                subtypes: DrupalTaxonomyTerm[], products: DrupalProductNode[],
                childTerms: DrupalTaxonomyTerm[], relatedNews: DrupalNode[]} }
              taxonomy_term={entity as ProductTypeTaxonomyTerm}
            />
          )}
        </>
      )}
      {entity?.type === 'taxonomy_term--industry_segments' && (
        <>
          {(Array.isArray(additionalContent?.['products']) && additionalContent?.['products'].length === 1) ? (
            // @todo let's handle this better for when we're rendering 1 product in a Product Type/Name.
            <NodeProduct
              key={key}
              node={additionalContent['products'][0] as DrupalProductNode}
              additionalContent={{
                relatedNews: additionalContent['relatedNews'],
                compatibleProducts: additionalContent?.['compatibleProducts'] ?? [],
                compatibleMeta: additionalContent?.['compatibleMeta'],
              }}/>
          ) : (
            <TaxonomyIndustrySegments
              key={key}
              additionalContent={additionalContent as {
                childTerms: IndustrySegmentsTaxonomyTerm[],
                products: DrupalProductNode[],
                relatedNews: DrupalNode[],
              }}
              taxonomy_term={entity as IndustrySegmentsTaxonomyTerm}/>
          )}
        </>
      )}
      {entity?.type === 'taxonomy_term--product_class' && (
        <>
          {(Array.isArray(additionalContent?.['products']) && additionalContent?.['products'].length === 1) ? (
              // @todo let's handle this better for when we're rendering 1 product in a Product Type/Name.
              <NodeProduct
                key={key}
                node={additionalContent['products'][0] as DrupalProductNode}
                additionalContent={{
                  relatedNews: additionalContent['relatedNews'],
                  compatibleProducts: additionalContent?.['compatibleProducts'] ?? [],
                  compatibleMeta: additionalContent?.['compatibleMeta'],
                }}/>
          ) : (
            <TaxonomyProductClass
              key={key}
              additionalContent={additionalContent as {
                children?: DrupalTaxonomyTerm[]
                products?: DrupalProductNode[]
                relatedNews?: DrupalNode[]
              }}
              taxonomy_term={entity as DrupalTaxonomyTerm}
            />
          )}
        </>
      )}
    </>
  );
}

// Generates static paths for the first 200 pages to pre-render.
// The default implementation prioritizes menu links. This can be customized
// depending on requirements. For example, for a blog, it could make sense
// to prioritize based on creation time.
// See https://nextjs.org/docs/basic-features/data-fetching/get-static-paths.
export async function getStaticPaths(
  context: GetStaticPathsContext,
): Promise<GetStaticPathsResult> {
  // By limiting the number of static paths, larger sites can keep build times
  // within a reasonable timeframe.
  const limit = process.env.ITEMS_LIMIT ? parseInt(process.env.ITEMS_LIMIT) : 200;
  // If in development mode, only fetch for the first context to prevent bottlenecks
  // and errors.
  const locales = context.locales;
  if (!process.env.LOCAL_PROD_BUILD) {
    context.locales = [context?.defaultLocale ?? 'en'];
  }
  const paths = await getPrioritizedStaticPathsFromContext(
    context,
    ENTITY_TYPES,
    // Build default language only to prevent an overly large build.
  );
  context.locales = locales;

  return {
    paths: paths.slice(0, limit),
    fallback: 'blocking',
  };
}

export async function getStaticProps(context): Promise<GetStaticPropsResult<EntityPageProps>> {
  // Find a matching path from Drupal from context.
  const start = performance.now();
  const NEXT_BUILD_MODE = process.env?.["NEXT_BUILD_MODE"] ?? false;
  const path = await drupal.translatePathFromContext(context);
  let displayContactUsForm = false;
  let customMenu: DrupalMainMenuLinkContent[] = [];
  let contactUsFormTitle = '';
  let newsletterPad = false;
  let hideUtilityMenu = false;
  let hideFooterMenu = false;
  let logoScrollToTop = false;
  let translatedUrls: TranslatedUrls|null;
  let redirectTo = '';
  let revalidateTTL: number|null = null;
  const slug = context?.params?.slug?.[0] ? context.params.slug.join('/') : 'unknown';

  if (process.env.NODE_ENV == 'development') {
    try {
      await testApiCompatibility(ENTITY_TYPES, drupal);
    }
    catch (error) {
      console.log(error);
    }
  }

  if (!path) {
    console.log(`Failed to fetch resource: path not found for slug: ${slug}.`);
    if (!NEXT_BUILD_MODE) {
      const destination = context.locale !== context.defaultLocale ? `/${context.locale}` : '/';
      return {
        redirect: {
          destination,
          statusCode: 301,
        },
      };
    }
    else {
      return {
        notFound: true,
        revalidate: 60,
      };
    }
  }

  // Handle redirects.
  if (path?.redirect) {
    const [redirect] = path.redirect;
    let redirectStatus = 302 as 301 | 302 | 303 | 307 | 308;
    // See if the redirect has resolved to a path (not just /node/n or /taxonomy/term/n etc).
    let redirectTo = (path?.resolved?.length && path?.entity?.path?.length) ? path.entity.path : redirect.to;
    try {
      redirectStatus = parseInt(redirect.status) as 301 | 302 | 303 | 307 | 308;
    }
    catch (error) {
      console.log(`Failed to parse redirect status code.`);
    }
    if (!NEXT_BUILD_MODE) {
      console.log(`Redirecting (${redirectStatus}) : ${redirectTo}`);
      return {
        redirect: {
          destination: redirectTo,
          statusCode: redirectStatus,
        },
      };
    }
    else {
      console.log(`Attempting redirect in build mode, returning 404 for: ${redirectTo}`);
      return {
        notFound: true,
        revalidate: 60,
      };
    }
  }

  const type = path?.jsonapi?.resourceName ?? '';

  if (!ENTITY_TYPES.includes(type)) {
    // @todo set this back to not found later.
    console.log(`Failed to fetch resource: ${path?.jsonapi?.individual ?? 'unknown'}`);
    if (!NEXT_BUILD_MODE) {
      const destination = context.locale !== context.defaultLocale ? `/${context.locale}` : '/';
      return {
        redirect: {
          destination,
          statusCode: 301,
        },
      };
    }
    else {
      return {
        notFound: true,
        revalidate: 60,
      };
    }
  }

  let additionalContent = {} as any;
  const params = new DrupalJsonApiParams();

  if (context?.preview) {
    params.addCustomParam({'resourceVersion': context?.previewData?.resourceVersion});
  }

  // params.addFields(type, ['translated_urls']);

  if (type === 'node--page') {
    params.addInclude(['field_page_image.image']);
  }

  if (type === 'node--article') {
    params.addInclude([
      'field_article_image.image',
      'field_article_type',
      'field_display_author',
    ]);
  }

  if (type === 'node--press_release') {
    params.addInclude([
      'field_pr_image.image',
    ]);
  }

  if (type === 'node--key_value_page') {
    params.addInclude([
      'field_kvp_hero_image.image',
    ]);
  }

  if (type === 'node--event') {
    params
      .addInclude([
        'field_event_hero_image.image',
        'field_event_type',
        'field_event_image.image',
        'field_event_image.bf_image'
      ])
      .addFields('node--place', ['title', 'path']);
  }

  if (type === 'media--video') {
    params.addInclude([
      'thumbnail',
      'field_video_thumbnail.image',
      'field_video_thumbnail.bf_image',
      'field_video_categories',
    ]);
  }

  if (type === 'node--product') {
    params.addInclude(getFullPageNodeProductIncludes());
    params.addFields('node--product', [
      'field_product_additional_docs',
      'field_product_products',
      'field_product_quick_guide',
      'field_product_training_title',
      'field_product_product_manual',
      'field_product_product_sheet',
      'field_product_safety_data_sheet',
      'field_product_brochure',
      'field_product_class',
      'field_product_benefits',
      'field_product_hero_image',
      'field_product_video',
      'field_product_training_videos',
      'field_product_training_vid_desc',
      'field_product_image',
      'metatag',
      'field_product_accessories_table',
      'field_product_software_patches',
      'body',
      'field_product_variations',
      'field_product_accessories_header',
      'field_comp_heading',
      'field_product_additional_label',
      'field_product_brand',
      'path',
      'field_product_twitter_card_image',
      'field_product_order_online_link',
      'field_product_order_online_aus',
      'field_product_benefits_summary',
      'field_product_brochure_scroll',
      'field_product_ref_guide_scroll',
      'field_product_manual_scroll',
      'field_product_facebook_image',
      'field_product_real_additionals',
      'field_product_testing_method',
      'field_product_display_title',
      'field_product_facebook_desc',
      'field_product_sheet_scroll',
      'field_product_workflow_type',
      'field_product_twitter_desc',
      'field_product_sds_scroll',
      'field_product_additionals',
      'field_comp_workflow_type',
      'field_product_certificates',
      'field_product_certs_no_file',
      'field_product_hero_video',
      'field_product_segment_step',
      'field_product_sub_category',
      'field_product_test_targets',
      'field_comp_description',
      'field_product_category',
      'field_product_workflow',
      'field_product_layout',
      'field_product_software',
      'field_product_enrichment',
      'field_product_sample_collection',
      'field_product_monitoring_systems',
      'field_product_automation',
      'field_product_ext_high_quality',
      'field_product_ext_throughput_h',
      'field_product_eq_high_quality',
      'field_product_eq_h_throughput',
      'field_product_instruments',
      'field_product_ext_automated',
      'field_product_ext_standard',
      'field_product_eq_automated',
      'field_product_eq_standard',
      'field_product_equipment',
      'field_product_aut_automated',
      'field_product_reg_automated',
      'field_product_reg_standard',
      'field_product_reg_high_quality',
      'field_product_reg_h_throughput',
      'type',
      'field_metatag',
      'langcode',
      'status',
      'title',
      'drupal_internal__nid',
      'id',
    ]);
    params.addFields('file--file', [
      'image_style_uri',
      'blur_image',
      'resourceIdObjMeta',
      'drupal_internal__fid',
      'uri',
      'id',
    ]);
    params.addFields('taxonomy_term--certificate_type', [
      'id',
      'name',
      'drupal_internal__tid',
    ]);
    params.addFields('media--document', [
      'id',
      'name',
      'drupal_internal__mid',
      'field_document_button_text',
      'field_media_file',
      'field_document_language',
      'field_document_region',
      'field_document_categories',
      'field_certificate_type',
    ]);
    params.addFields('taxonomy_term--document_category', [
      'name',
      'id',
      'drupal_internal__tid'
    ]);
    params.addFields('taxonomy_term--document_region', [
      'name',
      'id',
      'drupal_internal__tid'
    ]);
    params.addFields('taxonomy_term--document_language', [
      'name',
      'id',
      'drupal_internal__tid'
    ]);
  }

  if (type === 'taxonomy_term--product_type') {
    // Allow up to 4 levels of terms.
    params.addInclude([
      'field_product_type_image.image',
      'field_product_type_image.bf_image',
      'field_product_type_videos',
      'field_product_type_videos.thumbnail',
      'field_product_type_videos.field_video_thumbnail.image',
      'field_product_type_videos.field_video_thumbnail.bf_image',
      'parent',
      'parent.parent',
      'parent.parent.parent',
    ]);
    params.addFields('taxonomy_term--product_type', [
      'id',
      'type',
      'status',
      'name',
      'field_product_type_products',
      'field_product_type_alt_link',
      'field_product_type_child_desc',
      'field_product_type_child_title',
      'field_product_type_child_label',
      'field_product_type_show_test_met',
      'field_product_type_product_find',
      'field_product_type_hero_btn_text',
      'drupal_internal__tid',
      'field_product_type_listing_title',
      'path',
      'nodeorder',
      'field_product_type_display_title',
      'description',
      'field_product_type_listing_desc',
      'metatag',
      'field_product_type_image',
      'field_product_type_videos',
      'parent',
    ]);
    params.addFields('media--image', [
      'image',
      'type',
    ]);
    params.addFields('media--brandfolder_image', [
      'bf_image',
      'type',
    ]);
  }

  if (type === 'taxonomy_term--industry_segments') {
    params.addInclude([
      'parent',
      'field_industry_overview.field_media_file',
      'field_industry_segment_brochure.field_media_file',
      'field_industry_hero_image.image',
      'field_industry_hero_image.bf_image',
      'field_industry_segment_link_to',
      'field_industry_videos',
      'field_industry_videos.thumbnail',
      'field_industry_videos.field_video_thumbnail.image',
      'field_industry_videos.field_video_thumbnail.bf_image',
    ]);
  }

  if (type === 'taxonomy_term--product_class') {
    params.addInclude([
      'parent',
      'parent.field_class_product_type_name',
      'parent.field_class_product_type_name.parent',
      'parent.field_class_product_type_name.parent.parent',
      'parent.field_class_product_type_name.parent.parent.parent',
      'field_class_hero_image.image',
      'field_class_hero_image.bf_image',
      'field_class_product_type_name',
      'field_class_product_type_name.parent',
      'field_class_product_type_name.parent.parent',
      'field_product_class_detail_cards',
      'field_product_class_detail_cards.field_detail_card_icon',
    ]);
    params.addFields('file--file', [
      'image_style_uri',
      'blur_image',
      'resourceIdObjMeta',
      'id',
      'drupal_internal__fid',
    ]);
    params.addFields('media--image', [
      'image',
      'type',
    ]);
    params.addFields('media--brandfolder_image', [
      'bf_image',
      'type',
    ]);
  }

  // Entities refining fields should all have the translated_urls property.
  let fields = params.getQueryObject()?.fields?.[type] ? params.getQueryObject().fields[type].split(',') : [];
  if (fields?.length) {
    params.addFields(type, fields.concat(['translated_urls']));
  }

  let entity: DrupalNode|DrupalTaxonomyTerm|DrupalVideoMedia|null = null;
  // We always get the entity via an initial call, any subsequent requests for required
  // data should be amalgamated into a sub-request to prevent hammering the server.
  try {
    let s = performance.now();
    entity = await drupal.getResourceFromContext<
      DrupalNode | DrupalTaxonomyTerm
    >(type, context, {
      params: params.getQueryObject(),
    });
    if (!NEXT_BUILD_MODE) {
      console.log(`${((performance.now() - s) / 1000).toFixed(2)} seconds to get entity ${slug}.`)
    }
  }
  catch (error) {
    console.log(`Failed to fetch resource: ${path?.jsonapi?.individual ?? 'unknown'}`);
  }
  if (!entity && context.locale !== context.defaultLocale && path?.entity?.uuid) {
    // If a resource doesn't have a translation, fetching will fail in this case, we'll use the site default language, to account for
    // the lack of current translation. This will prevent build errors.
    entity = await drupal.getResource<
      DrupalNode | DrupalTaxonomyTerm | DrupalVideoMedia
    >(type, path?.entity?.uuid, {
      params: params.getQueryObject(),
    });
    // @todo retain in case we need to 404 empty translations in future, for now fallback.
    // return {
    //   notFound: true,
    //   revalidate: 60,
    // };
  }

  // At this point, we know the path exists and it points to a resource. If we
  // receive an error, it means something went wrong on Drupal. Let's log an
  // error, so we can see what's going on locally and return not found, so
  // we don't break the build process.
  if (!entity) {
    console.log(`Failed to fetch resource: ${path?.jsonapi?.individual ?? ''}`);
    return {
      notFound: true,
      revalidate: parseInt(REVALIDATE_TTL),
    };
  }

  // If we're not in preview mode and the resource is not published,
  // Return page not found.
  if (!context.preview && entity?.status === false) {
    // Purge if not in build mode.
    if (!NEXT_BUILD_MODE) {
      if (context?.params?.slug) {
        let purgePath = `${context?.locale ?? context?.defaultLocale}/${context?.params?.slug?.join('/')}`;
        await purgeSlug(purgePath);
      }
    }
    return {
      notFound: true,
      revalidate: parseInt(REVALIDATE_TTL),
    };
  }

  // Set the translated urls.
  translatedUrls = entity?.translated_urls;

  if (type === 'taxonomy_term--product_type') {
    ({entity, additionalContent, displayContactUsForm} = await TermProductTypeSlug(entity as ProductTypeTaxonomyTerm, additionalContent, context));
  }
  if (type === 'taxonomy_term--industry_segments') {
    ({entity, additionalContent, displayContactUsForm} = await TermIndustrySegmentsSlug(entity as IndustrySegmentsTaxonomyTerm, additionalContent, context));
  }
  if (type === 'node--product') {
    ({entity, additionalContent, displayContactUsForm} = await NodeProductSlug(entity as DrupalProductNode, additionalContent, context));
  }
  if (type === 'node--landing_page' && entity?.field_paragraphs) {
    ({hideUtilityMenu, hideFooterMenu, logoScrollToTop, newsletterPad} = await NodeLandingPageSlug(entity as DrupalNode, context, customMenu));
  }
  if (type === 'node--event') {
    ({displayContactUsForm, contactUsFormTitle} = await NodeEventSlug(entity as DrupalNode, context));
  }
  if (type === 'node--article') {
    ({entity, additionalContent} = await NodeArticleSlug(entity as DrupalNode, additionalContent, context));
    displayContactUsForm = true;
  }
  if (type === 'taxonomy_term--product_class') {
    ({entity, additionalContent, displayContactUsForm} = await TermProductClassSlug(entity as ProductClassTaxonomyTerm, additionalContent, context));
  }
  if (type === 'media--video') {
    revalidateTTL = 86400;
    ({entity, additionalContent, displayContactUsForm, contactUsFormTitle} = await MediaVideoSlug(entity as DrupalVideoMedia, additionalContent, context));
    if (translatedUrls && Object.keys(translatedUrls)?.length) {
      for (let locale of context.locales) {
        if (!translatedUrls[locale] && translatedUrls[context.defaultLocale]) {
          translatedUrls[locale] = `/${locale}${translatedUrls[context.defaultLocale]}`;
        }
      }
    }
  }

  // Add a dynamic OG image to product and term pages.
  if (['node--product', 'taxonomy_term--industry_segments', 'taxonomy_term--product_type', 'taxonomy_term--product_class'].includes(entity?.type)) {
    if (entity?.metatag?.length) {
      for (const tag of entity.metatag) {
        if (tag?.attributes?.property === 'og:image') {
          let imageUrl = tag.attributes.content;
          if (imageUrl?.length) {
            let params = new URLSearchParams();
            params.append('image', imageUrl);
            let metaTitle = entity.metatag.filter(tag => tag?.attributes?.name === 'title')?.[0]?.attributes?.content ?? '';

            // If we already have a title tag in the meta; use that.
            if (metaTitle?.length) {
              params.append('title', metaTitle);
            }
            // If it's a term, use the name.
            if (entity?.name) {
              params.append('title', entity.name);
            }
            // Products use a title with a brand + suffix combo.
            else if (entity?.title) {
              if (entity?.field_product_brand) {
              }
              params.append('title', `${entity?.field_product_brand?.name ? (
                entity.field_product_brand?.field_brand_suffix 
                  ? (entity.field_product_brand.name + entity.field_product_brand?.field_brand_suffix + ' ')
                  : entity.field_product_brand.name + ' '
              ) : ''}${entity.title}`);
            }

            if (entity?.body?.value?.length) {
              params.append('body', striptags(entity.body.value));
            }
            else if (entity?.description?.value?.length) {
              params.append('body', striptags(entity.description.value));
            }
            else if (entity?.field_class_hero_text?.value?.length) {
              params.append('body', striptags(entity.field_class_hero_text.value));
            }
            else if (entity?.field_product_type_display_title?.value?.length) {
              params.append('body', striptags(entity.field_product_type_display_title.value));
            }

            if (entity?.path?.alias?.length) {
              params.append('url', striptags(`https://www.hygiena.com${entity.path.alias}`));
            }

            tag.attributes.content = `${process.env["NEXT_SITE_URL"]}/api/og?${params.toString()}`;
          }
          break;
        }
      }
    }
  }

  // The Newsletter is discontinued, and the Drupal Webform for ContactUs
  // is pending CRM integration. Currently, we are only using an ActOn Form
  // we'll simply pass through a title stub if we're enabling the contact
  // us form.
  let contactUs: DrupalWebform|null = null;
  if (displayContactUsForm) {
    contactUs = {
      title: contactUsFormTitle ?? '',
    } as DrupalWebform;
  }

  if (!NEXT_BUILD_MODE) {
    const end = performance.now();
    console.log(`Slug ${slug} took ${((end - start) / 1000).toFixed(2)} seconds to build.`)
  }

  return {
    props: {
      entity: entity,
      entityInfo: {
        id: entity.id,
        type: entity.type
      },
      additionalContent,
      alertBanner: await getAlertBanner(context),
      webforms: {
        contactUs,
      },
      redirectTo,
      menus: await getMenus(context),
      blurMap: await getBlurMap(),
      metatag: entity?.metatag ?? null,
      newsletterPad: !newsletterPad,
      customMenu,
      hideUtilityMenu,
      hideFooterMenu,
      logoScrollToTop,
      dictionary: await getDictionary(context?.locale),
      translatedUrls,
    },
    revalidate: revalidateTTL ? revalidateTTL : parseInt(REVALIDATE_TTL),
  };
}
